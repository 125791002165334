// @formatter:off
body {
  // Sidenav
  --vex-sidenav-background: theme('colors.gray.900');
  --vex-sidenav-color: theme('colors.white');

  --vex-sidenav-toolbar-background: theme('colors.gray.900');
  --vex-sidenav-section-divider-color: theme('colors.white / 0.12');

  // Sidenav Item
  --vex-sidenav-item-background-hover: theme('colors.gray.950 / 30%');
  --vex-sidenav-item-background-active: theme('colors.blue.950 / 90%');
  --vex-sidenav-item-color: theme('colors.zinc.200 / 80%');
  --vex-sidenav-item-color-hover: theme('colors.white');
  --vex-sidenav-item-color-active: theme('colors.white');
  --vex-sidenav-item-dropdown-background: var(--vex-sidenav-item-background-active);
  --vex-sidenav-item-dropdown-background-hover: theme('colors.gray.950 / 80%');
  --vex-sidenav-item-dropdown-color-hover: var(--vex-sidenav-item-color-hover);
  --vex-sidenav-item-dropdown-background-active: var(--vex-sidenav-item-dropdown-background-hover);
  --vex-sidenav-item-dropdown-color-active: var(--vex-sidenav-item-color-active);
  --vex-sidenav-item-icon-color: theme('colors.zinc.500 / 60%');
  --vex-sidenav-item-icon-color-hover: theme('colors.blue.400 / 80%');
  --vex-sidenav-item-icon-color-active: theme('colors.blue.400 / 80%');
  --vex-sidenav-item-ripple-color: theme('colors.white / 10%');

  // Toolbar
  --vex-toolbar-icon-color: rgb(var(--vex-color-primary-600));

  // Secondary Toolbar
  --vex-secondary-toolbar-background: var(--vex-toolbar-background);

  // Navigation
  --vex-navigation-background: var(--vex-background-card);
}
// @formatter:on
