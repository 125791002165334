:root {
  --mdc-protected-button-container-color: 92 119 255;

  .mat-mdc-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button {
    @apply rounded-button min-w-[6rem];
  }

  .mat-button-toggle-group:not(.mat-button-toggle-vertical) {
    @apply rounded-button;

    .mat-button-toggle:first-child .mat-button-toggle-label-content {
      @apply pl-4;
    }

    .mat-button-toggle:last-child .mat-button-toggle-label-content {
      @apply pr-4;
    }
  }

  .mat-button-toggle-button {
    @apply font-medium;
  }

  .mat-mdc-icon-button {
    /* &.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 40px;
    }
    svg {
      --mdc-icon-button-icon-size: 1.125rem;
    } */
    .mat-icon {
      @apply inline-flex items-center justify-center;
    }
  }

  .sm-icon-button {
    width: 32px !important;
    height: 32px !important;
    padding: 0px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    & > *[role='img'] {
      width: 20px;
      height: 20px;
      font-size: 20px;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    fa-icon {
      display: inherit;
    }

    .mat-mdc-button-touch-target {
      width: 32px !important;
      height: 32px !important;
    }
  }

  .xs-icon-button {
    width: 24px !important;
    height: 24px !important;
    padding: 0px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    & > *[role='img'] {
      width: 16px;
      height: 16px;
      font-size: 16px;
    }

    svg {
      width: 16px;
      height: 16px;
    }

    fa-icon {
      display: inherit;
    }

    .mat-mdc-button-touch-target {
      width: 24px !important;
      height: 24px !important;
    }
  }
}
