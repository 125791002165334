@import "@xterm/xterm/css/xterm.css";

// Vex Core
@import "./@vex/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/
:root {
  --vex-button-border-radius: 4px;

  --vex-color-primary-500: 92 119 255;

  --vex-background-card-rgb: 255 255 255;
  --vex-background-card-rgb: 255 255 255;
}

:root {
  // fixes animation when using [hidden] - https://stackoverflow.com/a/35578093/186034
  [hidden] {
    display: none !important;
  }

  --loader-background: rgb(19, 41, 61);
  --open-drawer-height: 200px;
  --secondary-bar-icon-width: 48px;
  --task-drawer-height: 200px;
  --page-layout-header-height: 150px;

  body {
    background: var(--vex-background-background);
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    span.mat-button-wrapper {
      // fixes font awesome center alignment
      line-height: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
    mat-paginator {
      svg {
        // fixes issues with table paginator icons not being centered
        display: unset;
      }
    }

    blockquote.warn {
      border-color: crimson;
      background-color: lightpink;
    }
  }

  hr {
    border: 1px solid var(--foreground-divider);
  }

  div:not(.drawer-collapsed) {
    .footer-wrapper {
      // bottom: var(--footer-height);
      // position: relative;
    }
  }

  div.drawer-collapsed {
    .footer-wrapper {
      bottom: 0;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }

    100% {
      background-position: 1000px 0;
    }
  }

  .mat-mdc-tooltip {
    font-size: 0.8rem;
  }

  .animate {
    animation: shimmer 2s infinite;
    background: linear-gradient(to right, #eff1f3 4%, #c5bfbf 25%, #eff1f3 36%);
    background-size: 1000px 100%;
    color: #ffffff00;
    opacity: 0.5;
    border-radius: 6px;
  }

  .search-spinner {
    left: 0.5rem;
  }

  button.code-button {
    color: greenyellow;
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  vex-config-panel {
    .theme-overlay {
      @apply absolute h-full w-full top-0 right-0 bottom-0 left-0 ease-out transition-transform rounded;
      background: var(--vex-background-background);
    }
  }

  table {
    th.text-right > div {
      text-align: right;
    }

    th.justify-end > div {
      justify-content: flex-end;
    }

    td.process-name-width {
      max-width: 16rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    tbody {
      tr {
        .mat-accordion > .mat-expansion-panel-spacing {
          margin-bottom: 0;
          margin-top: 0;
        }

        .mat-accordion > .mat-expansion-panel-spacing:not(:first-child) {
          margin-top: 8px;
        }

        .mat-accordion > .mat-expansion-panel-spacing:first-child {
          margin-bottom: 8px;
        }

        .mat-accordion > .mat-expansion-panel-spacing:only-child {
          margin-bottom: 0px;
        }

        span.mat-expansion-indicator::before,
        span.mat-expansion-indicator::after {
          margin-bottom: 3px;
          border-color: rgba(0, 0, 0, 0.26);
        }
      }
    }
  }

  .density-condensed {
    table:not(.mat-calendar-table) {
      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      .mat-checkbox-inner-container {
        height: 1em;
        width: 1em;
      }

      td,
      th {
        &.process-name-width {
          max-width: 10rem;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-ripple {
          left: calc(50% - 13px);
          top: calc(50% - 13px);
          height: 26px;
          width: 26px;
        }

        div.mat-ripple-element {
          left: calc(50% - 13px) !important;
          top: calc(50% - 13px) !important;
          height: 26px !important;
          width: 26px !important;
        }
      }

      thead {
        tr {
          height: 2rem;
        }
      }

      tbody {
        tr.process-row-detail > td {
          padding: 0.5rem;

          .mat-expansion-panel-header {
            font-size: 12px !important;

            td {
              padding: 0;
            }
          }
        }

        tr {
          height: 2rem;
        }

        td {
          font-size: 0.75rem;

          a,
          button {
            width: 1.5rem;
            height: 1.5rem;
            font-size: 0.75rem;
            margin-top: 2px;
            margin-bottom: 2px;

            mat-icon {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  .mat-mdc-form-field:not(.mat-focused) {
    fa-icon {
      @apply text-secondary;
    }
  }

  .sidenav {
    .sidenav-items {
      height: calc(100% - var(--vex-toolbar-height));
    }

    &.collapsed {
      .item-label {
        @apply opacity-100;
      }

      &:not(.open) {
        .item-label {
          @apply opacity-0;
        }

        .sidenav-toolbar {
          .sub-title {
            @apply ps-6 opacity-0;
          }
        }
      }
    }
  }

  .fix-markdown-padding {
    pre {
      @apply rounded #{!important};
      code {
        @apply p-0 #{!important};
      }
    }
  }

  .error-snack {
    @apply text-white text-opacity-80 bg-red-500 max-w-lg;

    .mat-mdc-button {
      color: white;
    }
  }
}
