.mdc-notched-outline__notch {
  border-right: none;
}

:root {
  .mdc-text-field--outlined {
    --mdc-outlined-text-field-container-shape: theme('borderRadius.DEFAULT');
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-container-color: transparent;
  background-color: var(--mdc-filled-text-field-container-color);
}
