:root {
  .mat-mdc-form-field-error {
    @apply text-xs;
  }

  .mat-mdc-form-field-focus-overlay {
    --mat-form-field-state-layer-color: transparent;
    background-color: var(--mat-form-field-state-layer-color);
  }
}
